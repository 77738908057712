import Vue from 'vue'
//导入我们需要的element-ui 组件
import {Button, Message, MessageBox,Notification} from 'element-ui'
import { Form,FormItem,Input,Container,Aside,Header,Main,Menu,Submenu,MenuItem,Breadcrumb,BreadcrumbItem,Card,
        Row,Col,Table,TableColumn,Switch,Tooltip,Pagination,Dialog ,
        Tag,Tree,Select,Option,Upload,Image,InputNumber,Checkbox,DatePicker} from 'element-ui'


//注册我们需要的element-ui 组件
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(DatePicker)
//在很多组件里用到数据/实用工具，但是不想污染全局作用域 ，可以通过在原型上定义它们使其在每个 Vue 的实例中可用。
//$message 在所有的 Vue 实例中可以使用
//message 就是toast，confirm 就是一个确认对话框
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
