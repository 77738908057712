import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'

import Lunbo from "@/components/lunBo/Lunbo";
import LunboEdit from "@/components/lunBo/LunboEdit";

import DoctorInfo from "@/components/doctor/DoctorInfo";
import DoctorEdit from "@/components/doctor/DoctorEdit";
import DoctorImg from "@/components/doctor/DoctorImg"
import zhixunMain from "@/components/PatientInformation/zhixunMain";
import inspectionReport from "@/components/PatientInformation/inspectionReport";



import AuthenManager from "@/components/alluser/AuthenManager";
import AllUserManager from "@/components/alluser/AllUserManager";
import password from "@/components/password/password";
import contactMe from "@/components/concatus/contactMe";
import hospital from "@/components/concatus/hospital";

import DataSta from '@/components/report/DataSta.vue';
import Report_1 from '@/components/report/Report_1.vue';
import Report_2 from '@/components/report/Report_2.vue';
import Report_3 from '@/components/report/Report_3.vue';
import Report_4 from '@/components/report/Report_4.vue';
import Report_5 from '@/components/report/Report_5.vue';



Vue.use(VueRouter)
// 定义路由规则
const routes = [
  {
    // 指定访问的路径
    path: '/login',
    // 指定要展示的组件
    component: Login
  },
  { // 重定向， 当打开的是 /目录的时候，直接重定向到 /login中
    path: '/',
    redirect: '/login'
  },
  { path: '/home',
    component: Home ,
    redirect: "/DoctorInfo",  //打开 home界面这里会重定向到他的子组件welcome中，welcome 会显示在 home中的 <router-view></router-view>  那里
    children: [
      {  path: '/DoctorInfo',name:"DoctorInfo", component: DoctorInfo },
      {  path: '/DoctorEdit',name:"DoctorEdit", component: DoctorEdit },
    {  path: '/DoctorImg',name:"DoctorImg", component: DoctorImg },

      {  path: '/AuthenManager',name:"AuthenManager", component: AuthenManager },
      {  path: '/AllUserManager',name:"AllUserManager", component: AllUserManager },
      {  path: '/zhixunMain',name:"zhixunMain", component: zhixunMain },
      {  path: '/inspectionReport',name:"inspectionReport", component: inspectionReport ,props: route => ({
        isEdit: route.params.isEdit === 'true', // 转换为布尔值
        PatientReportID: route.params.PatientReportID
    })},
      
      {  path: '/lunbo',name:"lunbo", component: Lunbo },
      {  path: '/lunboEdit',name:"lunboEdit", component: LunboEdit },
      {  path: '/password',name:"password", component: password },
      {  path: '/contactMe',name:"contactMe", component: contactMe },
      {  path: '/hospital',name:"hospital", component: hospital },

      {  path: '/DataSta',name:"DataSta", component: DataSta },
      {  path: '/Report_1',name:"Report_1", component: Report_1 },
      {  path: '/Report_2',name:"Report_2", component: Report_2 },
      {  path: '/Report_3',name:"Report_3", component: Report_3 },
      {  path: '/Report_4',name:"Report_4", component: Report_4 },
      {  path: '/Report_5',name:"Report_5", component: Report_5 },

    ]
  },

]


const router = new VueRouter({
  routes
})

// 挂载路由导航守卫 ,如果用户没有登录，但是直接通过url访问特定页面，需要重新导航到登录页面
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  if (to.path === '/login') return next() //如果是要访问 login界面，直接放行
  const tokenStr = window.sessionStorage.getItem('token') //获取token
  if (!tokenStr) return next('/login') //如果token是空，直接到login界面
  next() //如果token不为空，放行（想去哪去哪）
})

//其他页面中通过 this.$router 可以访问到这个路由
export default router
