<template>
<div>
    <!-- 面包 导航-->
    <el-breadcrumb separator="/">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>检查信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <el-card class="container">
       <el-form ref="myForm" :model="searchModel" label-width="80px" class="searchForm" :rules="rules">
    <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="7">
            <el-form-item label="患者姓名:" prop="PatientName">
                <el-input v-model="searchModel.PatientName" placeholder="请输入患者姓名"></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="9" >
            <el-form-item label="检查类型:" label-width="80px" prop="Check_type" style="padding-left: 60px">
                <el-select v-model="searchModel.Check_type" placeholder="请选择检查类型" style="width: 100%;">
                    <el-option label="CT" value="CT"></el-option>
                    <!-- <el-option label="CTMR" value="CTMR"></el-option> -->
                    <el-option label="MR" value="MR"></el-option>
                    <el-option label="US" value="US"></el-option>
                    <el-option label="ES" value="ES"></el-option>
                    <el-option label="PIMS" value="PIMS"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="8" :offset="1">
            <el-form-item label="检查日期" label-width="80px" prop="dateArr">
                <el-date-picker v-model="searchModel.dateArr" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;" value-format="yyyyMMdd">
                </el-date-picker>
            </el-form-item>
        </el-col>
       <el-col :xs="24" :sm="12" :md="28" :lg="7" style="display: flex; justify-content: center;">
    <button @click="submitForm" style="width:70px;height:40px;margin-left:90px">
        查询
    </button>
</el-col>
    </el-row>
</el-form>
    </el-card>
    <!-- 结果列表 -->
    <el-card class="container">
        <el-table :data="doctorList" border stripe style="width: 100%" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column prop="PatientName" label="患者姓名" width="100">
            </el-table-column>

            <el-table-column prop="PatientGender" label="性别" width="80">
            </el-table-column>

            <el-table-column prop="PatientAge" label="年龄" width="80">
            </el-table-column>

            <el-table-column prop="PatientCheckType" label="检查类型" width="100">
            </el-table-column>
            <el-table-column prop="PatientCheckID" label="患者检查ID" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PatientCheckItem" label="患者检查项目" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PatientCheckDate" label="检查时间" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PatientReportID" label="患者报告ID" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="PatientImageID" label="患者图像ID" width="150" :show-overflow-tooltip="true">
            </el-table-column>

            <el-table-column prop="CardNumber" label="卡号" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column label="操作" width="160" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="openEditUI(scope.row)" type="text">查看报告</el-button>
                    <el-button @click="openViewimg(scope.row)" type="text">查看影像</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>

    <!-- 分页组件 -->
    <el-pagination v-if="ID!=0" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="searchModel.page" :page-sizes="[5, 10, 20, 50]" :page-size="searchModel.limit" layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
</div>
</template>

<script>
import axios from "axios";
export default {
    name: "zhixunMain",

    data() {
        return {
            doctorList: [],
            ID:1,
            total: 0,
            isEdit: false,
            patientReportID: null,
            searchModel: {
                Check_type: "",
                PatientName: "",
                // start_date: "",
                // end_date: "",
                dateArr: [],
                page: 1,
                limit: 5,
            },
            rules: {
                PatientName: [{
                    required: false,
                    message: '请输入患者姓名',
                    trigger: 'blur'
                }],
                Check_type: [{
                    required: false,
                    message: '请选择检查类型',
                    trigger: 'change'
                }],
                dateArr: [{
                    required: true,
                    type: 'array',
                    min: 1,
                    message: '请选择检查日期',
                    trigger: 'change'
                }]
            },
            value: "",
        };
    },
    mounted() {
        // this.getDoctorInfoList();
    },
    created() {
        this.searchFun();
    },
    methods: {
        submitForm() {
            this.$refs.myForm.validate((valid) => {
                if (valid) {
                    this.getDoctorInfoList();
                } else {
                    console.log('表单验证失败!');
                    return false;
                }
            });
        },
        async searchFun() {
            let total = 1;
            console.log("Request URL:", `${this.$REPORT_URL}get_all_patient?ID=${this.ID}`);
            await fetch(`${this.$REPORT_URL}get_all_patient?ID=${this.ID}`, {
                    method: "POST", // or 'PUT'
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => response.json()) // 解析JSON格式的响应
                .then((data) => console.log((this.doctorList = data.data))) // 输出数据
                .catch((error) => console.error("Error:", error));
        },

        async getDoctorInfoList() {
            this.doctorList = []
            if (!this.searchModel.dateArr || this.searchModel.dateArr.length !== 2) {
                console.error("请选择正确的日期范围");
                return;
            }
            var [start_date, end_date] = this.searchModel.dateArr;
            await fetch(
                    `${this.$REPORT_URL}get_patient?Check_type=${encodeURIComponent(this.searchModel.Check_type)}&PatientName=${encodeURIComponent(this.searchModel.PatientName)}&page=${this.searchModel.page}&limit=${this.searchModel.limit}&start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => response.json()) // 解析JSON格式的响应
                .then((data) => console.log((this.doctorList = data.data))); // 输出数据
        },
         // 当前分页中展示的item个数
        handleSizeChange(pageSize) {
            this.searchModel.limit = pageSize;
             this.searchFun()
            console.log(pageSize, "pageSize");
        },
        //页面切换（切换到其他分页）
        handleCurrentChange(pageNo) {
            this.searchModel.page = pageNo;
            this.ID=pageNo
        //   if(pageNo!=0 && pageNo--){
        //    this.searchFun()
        //   }
            
            this.getDoctorInfoList();
            
            // console.log(pageNo, "pageNo");
        },
        //是否在优选教师中显示
        async doctorSelectionChanged(info) {
            const {
                data: res
            } = await this.$http.post("/doctorinfo/update", info);
            // console.log("lunBo update-->", res)
            if (res.code !== 2000) {
                this.$notify.error({
                    title: "错误",
                    message: res.message,
                });
                info.doctorSelection = !info.doctorSelection;
            } else {
                this.$notify.success({
                    title: "成功",
                    message: res.message,
                });
            }
        },
        //该教师信息是否展示
        async doctorStatusChanged(info) {
            const {
                data: res
            } = await this.$http.post("/doctorinfo/update", info);
            console.log("doctorStatus update-->", res);
            if (res.code !== 2000) {
                this.$notify.error({
                    title: "错误",
                    message: res.message,
                });
                info.doctorStatus = !info.doctorStatus;
            } else {
                this.$notify.success({
                    title: "成功",
                    message: res.message,
                });
            }
        },

       
        // 查看报告
        // openEditUI(info) {
        //     this.$router.push({
        //         name: "DoctorEdit",
        //         params: {
        //             isEdit: true,
        //             PatientReportID: info.PatientReportID,
        //         },
        //     });
        //     console.log(info, "info");
        // },
        // 查看报告
        openEditUI(info) {
            this.$router.push({
                name: "inspectionReport",
                // query: {
                //     isEdit: true,
                //     PatientReportID: info.PatientReportID,
                // },
            });
            sessionStorage.setItem('patientReportID', info.PatientReportID);
      console.log('PatientReportID 已存储:', info.PatientReportID);
            // console.log(info.PatientReportID, "info.PatientReportID");
        },
        // 查看影像
        async openViewimg(info) {
            await fetch(`${this.$REPORT_URL}get_image?ID=${info.PatientImageID}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(data => {
                    let arr = data.data
                    arr.forEach(item => {
                        // console.log(item.ImageURL,"ImageURL?????");
                        let imageUrl = item.ImageURL
                        window.open(imageUrl, '_blank');
                    });
                    // this.doctorReportArr = data.data;
                })
                .catch(error => console.error('Error:', error));

        },
    },
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
    margin-bottom: 20px;
}

.container {
    margin-top: 20px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    // padding: 20px;
    // background-color: #f9fafc;
}
/* 自定义样式 */
.el-row {
    margin-bottom: 20px;
}

.form-item {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .el-col {
        margin-bottom: 10px;
    }
}
.myNote {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.el-range-editor .el-input__inner {
    width: 200px;
    height: 40px;
}

.el-input--suffix .el-input__inner {
    width: 200px;
    height: 40px;
}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
}

// .searchForm .el-form-item{
//     width: 200px;
//     height: 60px;
// }
</style>
