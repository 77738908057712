<template>
  
</template>

<script>
export default {
  data() {
        return {

        }
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>

</style>