import { render, staticRenderFns } from "./Report_2.vue?vue&type=template&id=0db86422&scoped=true"
import script from "./Report_2.vue?vue&type=script&lang=js"
export * from "./Report_2.vue?vue&type=script&lang=js"
import style0 from "./Report_2.vue?vue&type=style&index=0&id=0db86422&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db86422",
  null
  
)

export default component.exports