<template>
<div class="report-container">
    <div v-for="(item, index) in doctorReportArr" :key="index" class="report-wrapper">
        <!-- left -->
        <div class="left-content">
            <header class="report-header">
                <div @click="goBack" class="back-button">
                    <i class="el-icon-arrow-left" style="font-size: 22px; color: #333;"></i>
                </div>
                <div class="options"></div>
            </header>
            <section class="report-details">
                <div class="patient-info">
                    <span>{{ item.Report_Patient_Name }}</span>
                    <span style="padding-left: 20px">{{ item.Report_Patient_Gender }}/{{ item.Report_Patient_Age }}</span>
                </div>
                <div class="details-grid">
                    <div class="detail-item">
                        <label>患者编号:</label>
                        <span>{{ item.Report_Patient_ID }}</span>
                    </div>
                    <div class="detail-item">
                        <label>检查区域:</label>
                        <span>{{ item.Report_Check_Area }}</span>
                    </div>
                    <div class="detail-item">
                        <label>检查编号:</label>
                        <span>{{ item.Report_Check_ID }}</span>
                    </div>
                    <div class="detail-item">
                        <label>检查类型:</label>
                        <span>{{ item.Report_Check_Type }}</span>
                    </div>
                    <div class="detail-item">
                        <label>检查项目:</label>
                        <span>{{ item.Report_Check_Item }}</span>
                    </div>
                    <div class="detail-item">
                        <label>检查部位:</label>
                        <span>{{ item.Report_Check_Part }}</span>
                    </div>
                </div>
            </section>
            <section class="report-content" style="font-size: 13px;">
                <h4>影像所见:</h4>
                <p>{{ item.Report_Check_See }}</p>
                <h4>影像诊断:</h4>
                <p>{{ item.Report_Check_Diagnose }}</p>
            </section>
            <footer class="report-footer" style="font-size: 13px;">
                <span>报告医生: {{ item.Report_Write_Doctor }}</span>
                <span>审核医生: {{ item.Report_Process_Doctor }}</span>
                <span>报告检查日期: {{ item.Report_Check_Date }}</span>
            </footer>
        </div>
        <!-- right -->
        <div class="right-content">
            <iframe :src="item.Report_Image_Url" width="100%" height="100%" type="application/pdf"></iframe>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            patientName: '成彩莲',
            gender: '女',
            age: '77',
            patientId: 'P123456789',
            doctorReportArr: [],
            checkId: 'C250224336',
            checkDate: '2025-02-24',
            checkType: 'US',
            checkItem: '子宫双侧附件...',
            checkArea: '',
            showReportContent: false,
            reportContent: `子宫双侧附件：宫体大小约24×20×23mm，形态正常，内膜显示不清，宫壁回声不均匀，其内可见多个点状强回声，后方声影不明显。左侧附件区可见一大小约41×31mm的无回声区，壁薄光滑，内透声好，后方回声增强。盆底：静息状态下测量：残余尿量约5ml，逼尿肌厚度约3.8mm。`,
            diagnosisContent: `绝经后子宫；宫壁多发钙化；左侧附件区囊肿，O-RADS 2类；前盆腔：膀胱尿道后角开放，膀胱膨出（轻度，Green分型I）、膀胱颈移动度增大；后盆腔：直肠膨出（轻度）；肛提肌裂孔面积增大。`,
            doctor: '罗海楠',
            reviewer: '',
            reportDate: '2025-02-24',
            genderIcon: 'https://example.com/female-icon.png',
            patientReportID: null,
        };
    },
    created() {
        this.getInspectionReport();
    },
    methods: {
        goBack() {
            window.history.back();
        },
        showReport() {
            this.showReportContent = !this.showReportContent;
        },
        async getInspectionReport(id) {
            // 组件挂载时从 sessionStorage 读取 patientReportID
            const storedPatientReportID = sessionStorage.getItem('patientReportID');
            if (storedPatientReportID) {
                this.patientReportID = storedPatientReportID;
                console.log('已读取 PatientReportID:', this.patientReportID);
            } else {
                console.warn('未找到 PatientReportID');
            }
            await fetch(`${this.$REPORT_URL}get_report?ID=${this.patientReportID}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(data => {
                    this.doctorReportArr = data.data;
                })
                .catch(error => console.error('Error:', error));
        }
    }
};
</script>

<style scoped>
.report-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    /* 设置整个容器的高度为视口高度 */
    overflow: hidden;
    /* 防止页面出现滚动条 */
}

.report-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    /* 确保每个报告项的高度占满整个视口 */
}

.left-content {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    /* 如果左侧内容过多，允许滚动 */
    height: calc(100vh - 40px);
    /* 减去外边距 */
}

.right-content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* 防止右侧内容出现滚动条 */
    height: calc(100vh - 40px);
    /* 减去外边距 */
}

.right-content iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: auto;
    /* 允许PDF内容滚动 */
}

.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    border-radius: 20px;
}

.back-button {
    background: none;
    border: none;
    color: #333;
    font-size: 20px;
}

.patient-info {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
}

.options {
    display: flex;
    align-items: center;
}

.report-details {
    padding: 12px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.detail-item {
    margin-bottom: 10px;
    font-size: 13px;
}

.report-content {
    border-top: 1px dashed #ccc;
}

.report-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 1px dashed #ccc;
    padding-top: 20px;
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
</style>
